function getRankString(attributeString) {
  const delimIdx = attributeString.indexOf("rank=");
  if (delimIdx < 0) {
    return null;
  }
  const endDelimIdx = attributeString.indexOf("]", delimIdx + 5);
  return attributeString.substring(delimIdx + 5, endDelimIdx)
}


/**
 * Determine if `node` taxonomic rank is at most `cutoff`
 *
 * Ex:
 *   node.annotations = "...class...", cutoff = "order" => true
 *   node.annotations = "...order...", cutoff = "class" => false
 *
 * Search is performed using a simple contains operation
 *
 * ``false`` is returned for invalid ``cutoff`` values
 *
 * @param {String} cutoff Taxonomic cutoff rank/level. Will only accept ranks higher than this.
 * @param {Object} node Phylotree ``PhyloNode`` object.
 */
export function isAtMost(cutoff, node) {
  const ranks = ["phylum", "class", "order", "family", "genus", "species"];
  const levelIdx = ranks.indexOf(cutoff);
  if (levelIdx < 0) {
    return false;
  }
  const nodeRank = getRankString(node.data.attribute ? node.data.attribute : "");
  if (nodeRank === null) {
    return false;
  }
  for (const rank of ranks.slice(0, levelIdx)) {
    if (nodeRank.includes(rank)) {
      return true;
    }
  }
  return false;
}

/**
 * Determine if `node` tax rank equals `rank`
 *
 * ``false`` is returned for invalid ``rank`` values
 *
 * @param {String} rank Taxonomic cutoff rank/level.
 * @param {Object} node Phylotree ``PhyloNode`` object.
 */
export function isRank(rank, node) {
  const nodeRank = getRankString(node.data.attribute ? node.data.attribute : "");
  if (nodeRank === null) {
    return false;
  }
  return nodeRank.includes(rank);
}

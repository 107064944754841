import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';
import 'phylotree/dist/phylotree.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { phylotree } from 'phylotree';
import { selectAllDescendants } from 'phylotree/src/render/menus';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as d3 from 'd3';
import { SelectAll, RankSelector, ToastDisplay, LayoutSelector, VisualizeButton, ClearSelection } from "../utils/Selection.react";
import { isRank } from '../utils/NCBI.react';


/**
 * Get center of radial tree
 *
 * @param {Object} tip Tip on tree
 */
function getCenter(tip) {
  const rXLength = tip.radius * Math.cos(tip.angle);
  const rYLength = tip.radius * Math.sin(tip.angle);
  return [tip.screen_x - rXLength, tip.screen_y - rYLength];
}


/**
 * Calculate the starting/stopping angle for each arc centered about the angle of each tree leaf node
 *
 * @param {number} index Index of node in sorted tips
 * @param {Array} tips Sorted phylogenetic tips (leaf nodes)
 */
function getStartStop(index, tips) {
  const c = index + 1 < tips.length ? index + 1 : 0;
  const a = index - 1 < 0 ? tips.length - 1 : index - 1;
  let c_angle = tips[c].angle + Math.PI / 2;
  let a_angle = tips[a].angle + Math.PI / 2;;
  let b_angle = tips[index].angle + Math.PI / 2;;
  if (b_angle < a_angle) {
    b_angle += Math.PI * 2.;
    c_angle += Math.PI * 2.;
  } else if (c_angle < b_angle) {
    c_angle += Math.PI * 2.;
  }
  const edges = [b_angle - (b_angle + a_angle) / 2., (c_angle + b_angle) / 2. - b_angle];
  const size = edges.reduce((a, b) => a < b ? a : b);
  return [b_angle - size, b_angle + size];
}


/**
 * Phylotree Dash component.
 *
 * Generate a phylogenetic tree for a newick string and apply layout changes based on provided
 * metadata, taxonomic ranks, and other properties.
 */
export default function PhylotreeDash({ id, newick, metadata, rings, display_rings, starting_rank, ranks, selected_nodes, n_clicks, colors,
  trait_bubble_size, left_offset, max_radius, display_visualize_button,
  height, width, setProps }) {
  // Internal state: track the current tree layout, selected taxonomic ranks, # of times visualize selected
  const [displayView, setDisplayView] = useState("radial");
  const [rank, setRank] = useState(starting_rank);
  const [nClicks, setNClicks] = useState(n_clicks);
  const [nToggled, setNToggled] = useState(0);
  const [lastSelected, setLastSelected] = useState("");
  const [show, setShow] = useState(false);
  const [displayRings, setDisplayRings] = useState(display_rings);
  const [phylotreeObj, setPhylotreeObj] = useState(null);
  const [showInternalNodes, setShowInternalNodes] = useState(false);
  // Define reference for use in binding phylotree object
  const ref = React.useRef();
  const metadataKeys = Object.keys(metadata);
  const [zoomValue, setZoomValue] = useState(0);
  const taxonomicRanks = ["phylum", "class", "order", "family", "genus", "species"];
  const [selectedRankIdx, setSelectedRankIdx] = useState(5);

  /**
   * Create color map between all values of the selected rank (e.g., all different species) and assigns them a color
   * value for use in the tree visualizer. Not guaranteed to be unique.
   *
   * @param {string} rank Selected tax rank
   * @returns {{[p: string]: string}} Mapping between each different rank value and a color
   */
  const generateColorMap = (rank) => {
    // Generate color gradient based on number of taxa are present for the requested rank
    let taxaColors = Object.fromEntries(
      Object.entries(metadata).map(([_, v]) => [v[rank], ""]));
    const numTaxa = Object.keys(taxaColors).length;
    const colorScale = chroma.scale(colors).domain([0, numTaxa - 1]);

    // Map to a value within the scale
    taxaColors = Object.fromEntries(
      Object.entries(taxaColors).map(([k, _], i) => [k, colorScale(i).hex()]));
    // Set color for each node based on taxa selection
    return Object.fromEntries(
      Object.entries(metadata).map(([k, v]) => [k, taxaColors[v[rank]]]));
  };

  /**
   * Get the size of a node based on the presence of arbitrary trait data within the caller-provided metadata
   * @param {Object} node Phylotree node
   * @returns {number} Size of bubble
   */
  const generateBubbleSize = (node) => {
    const nodeData = metadata[node.data.name];
    // Empty or missing traits
    if (!nodeData || !nodeData.traits) {
      return 1;
    }
    // Trait is present for node
    if (nodeData.traits.size === 1) {
      return trait_bubble_size;
    }
    // Trait is not present
    return 1;
  };

  /**
   * Get nodes. If selected node is internal, all descendent leaves are returned. Otherwise, the selected node alone
   * is returned.
   *
   * @param {Object} node Phylotree node
   * @returns {{[p: string]: boolean}} Mapping of all nodes (self and descendent) based on selection
   */
  const getSelectedNodes = (node) => {
    const nodes = {};
    if (!node.children) {
      nodes[node.data.name] = true;
    } else {
      const descendentNodes = selectAllDescendants(node, true, false);
      for (const node of descendentNodes) {
        if (!node || Object.keys(node).length === 0) {
          continue;
        }
        nodes[node.data.name] = true;
      }
    }
    return nodes;
  };

  /**
   * Based on selected nodes, toggle descendents in current node selection.
   *
   * @param {Object} node Node selected by user
   * @param {Object} selectedNodes Currently selected nodes
   * @returns {{[p: string]: boolean}} Mapping of all nodes based on selection
   */
  const toggleSelectedNodes = (node, selectedNodes) => {
    const nodes = getSelectedNodes(node);
    for (const node of Object.keys(selectedNodes)) {
      if (nodes[node]) {
        delete nodes[node];
      } else {
        nodes[node] = true;
      }
    }
    return nodes;
  };

  /**
   * Set phylotree binding reference to a PhyloTree instance
   *
   * @param t Phylotree tree
   */
  const setTreeView = (t) => {
    // Required to render phylotree within React
    ref.current.replaceChildren(t.display.show());
  };

  /**
   * Nodes and text sizing change for hover of non-selected nodes
   *
   * @param {Object} element Current node d3 element
   * @param {Node} node Node data
   * @param {Object} s Selected nodes
   */
  const callOutSelectedNode = (element, node, s) => {
    element.on("mouseover", () => {
      if (s[node.data.name]) {
        return;
      }
      element.select("text").style("font-size", "16px");
      // TODO: Call out terminal nodes if selection is of an internal node
      // const descendentNodes = selectAllDescendants(node, true, false);
    });
    element.on("mouseout", () => {
      if (s[node.data.name]) {
        return;
      }
      element.select("text").style("font-size", "12px");
    });
  };

  /**
   * Color node (if present)
   *
   * Override with default color and size for selected nodes circles and text
   *
   * @param {Object} element Current node d3 element
   * @param {Object} node Node data
   * @param {Object} s Selected nodes
   */
  const colorNodeByMetadataAndSelection = (element, node, s) => {
    const nodeData = metadata[node.data.name];
    if (nodeData && nodeData.traits) {
      if (s[node.data.name]) {
        // Selected node has own color and size
        element.selectAll("circle").style("fill", "#AC4D39");
        element.select("text").style("font-size", "16px");
        element.style("fill", "#AC4D39");
      } else {
        if (nodeData.traits.color) {
          element.selectAll("circle").style("fill", nodeData.traits.color);
        }
      }
    }
  };

  const ZoomSlider = () => {

    const zoomHandler = () => {
      setShowInternalNodes(false);
      const transform = d3.zoomTransform(d3.select(".phylotree-container"));
      transform.k = zoomValue / 25. + 1.; // Based on k value 1 to 5;
      phylotreeObj.svg.attr("transform", transform);
      phylotreeObj.update()
    };

    const sliderChangeHandler = event => {
      const newValue = parseInt(event.target.value, 10);
      setZoomValue(newValue);
    };

    if (phylotreeObj)
      phylotreeObj.svg.call(zoomHandler);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} className="pb-2">
        <input
          type="range"
          min="0"
          max="100"
          step="5"
          value={zoomValue}
          onChange={sliderChangeHandler}
        />

      </div>
    );
  };

  const TaxaSelector = () => {

    const selectHandler = () => {
      for (const node of phylotreeObj.phylotree.getNodes()) {
        // node.collapsed = false;
      }
      // phylotreeObj.placenodes();
      // TODO: Taxa-level rendering - i.e, prune tree
      if (phylotreeObj) {
        const cutoffRank = taxonomicRanks[selectedRankIdx];
        for (const node of phylotreeObj.phylotree.getNodes()) {
          if (isRank(cutoffRank, node)) {
            console.log(node);
            // phylotreeObj.toggleCollapse(node);
            // node.collapsed = true;
          }
        }
        // phylotreeObj.placenodes();
        // setShowInternalNodes(true);
        phylotreeObj.update();
      }
    };


    const radioChangeHandler = event => {
      console.log(event);
    }

    if (phylotreeObj) 
      phylotreeObj.svg.call(selectHandler);
    

    return (
      <div className="pb-2">
        {taxonomicRanks.map(rank => { {<input type="radio" value={rank} name="rank" onChange={radioChangeHandler} />} })}
      </div>
    );
  };

  /* TODO: Make legend hover on top left */
  /**
   * Generate legend on SVG
   *
   * @param {Object} svg SVG element on which to generate legend
   * @param {Array} legendLabels List of labels
   * @param {Array} legendColors Color of each label indicator (circle)
   * @param {number} xPos X position
   * @param {number} yPos Y position
   */
  const createLegend = (svg, legendLabels, labelColors, xPos, yPos) => {
    const color = d3.scaleOrdinal().domain(legendLabels).range(labelColors);
    const legendLabelsIdx = legendLabels.map((v, i) => [v, i]);

    const circleSize = d => displayRings[d[1]] ? 7 : 4;
    const hoverCircleSize = d => displayRings[d[1]] ? 8 : 5;

    const updateDisplayRings = event => {
      const displayStatuses = [...displayRings];
      const elem = displayStatuses[event.srcElement.__data__[1]];
      displayStatuses[event.srcElement.__data__[1]] = !elem;
      setDisplayRings(displayStatuses);
    };

    // TODO: Link text/circle hover callout on hover of either element
    // TODO: Legend hover at top-left of window even with scrolling
    svg.append("g")
      .selectAll()
      .data(legendLabelsIdx)
      .enter()
      .append("circle")
      .attr("cx", xPos)
      .attr("cy", (_, i) => yPos + i * 25)
      .attr("r", circleSize)
      .on("click", event => updateDisplayRings(event))
      .on("mouseover", event => d3.select(event.target).attr("r", hoverCircleSize))
      .on("mouseout", event => d3.select(event.target).attr("r", circleSize))
      .style("fill", d => color(d[0]));

    svg.append("g")
      .selectAll()
      .data(legendLabelsIdx)
      .enter()
      .append("text")
      .attr("x", xPos + 20)
      .attr("y", (_, i) => yPos + i * 25)
      .attr("font-size", d => displayRings[d[1]] ? 16 : 15)
      .style("fill", d => displayRings[d[1]] ? "black" : "#D3D3D3")
      .text(d => d[0])
      .on("click", event => updateDisplayRings(event))
      .on("mouseover", event => d3.select(event.target).attr("font-size", d => hoverCircleSize(d) + 9))
      .on("mouseout", event => d3.select(event.target).attr("font-size", d => circleSize(d) + 9))
      .attr("text-anchor", "left")
      .style("alignment-baseline", "middle");
  }


  /**
   * Render ring around the tree
   *
   * @param {Object} ring Selected ring to render 
   * @param {Object} metadata Provided metadata from top-level callback
   * @param {number} ringIdx Index of ring within `metadata` object
   * @param {Array} sortedPoints Sorted phylogenetic tips (leaf nodes)
   * @param {TreeRender} renderedTree Generated phylogenetic tree from `phylotree` library
   * @param {number} innerRadius Inner radius/inner ring size
   * @param {number} ringRadius Ring radius
   */
  const renderRing = (ring, metadata, ringIdx, sortedPoints, renderedTree, innerRadius, ringRadius, center) => {
    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(innerRadius + ringRadius);

    let color = (_) => "white";
    if (ring.kind === "categorical") {
      color = d3.scaleOrdinal()
        .domain(Array.from(Array(ring.colors.length), (_, i) => i))
        .range(ring.colors);
    } else if (ring.kind === "linear") {
      color = chroma.scale(ring.colors).domain([0, 1]);  // TODO: Calculate based on the range of provided data
    }
    const s = {};
    for (const node of selected_nodes) {
      s[node] = true;
    }

    // Render rings
    renderedTree.svg.select(".phylotree-container")
      .append("g")
      .attr("transform", `translate(${center[0]},${center[1]})`)
      .selectAll()
      .data(sortedPoints)
      .enter()
      .append("path")
      // TODO: This should be a better implementation - 3 levels of indirection to get a single value is not ideal
      .attr("fill", p => {
        const traits = metadata[p.name].traits;
        return color(traits ? traits.rings[ringIdx] : null);
      })
      .attr("d", arc)
      .attr("stroke", "#E5E4E2")
      .on("click", event => {
        const node = event.srcElement.__data__.tip;
        const nodes = toggleSelectedNodes(node, s);
        setNToggled(Math.abs(Object.keys(nodes).length - selected_nodes.length));
        setLastSelected(node.name);
        setShow(true);
        setProps({ selected_nodes: Object.keys(nodes) });
      })
      .attr("stroke-width", 1)
      .append("title")
      .text(d => d.name);
  }


  /**
   * Render user-defined list of rings around the radiual phylogenetic tree
   *
   * @param {Object} metadata Provided metadata from top-level callback
   * @param {Array} rings User-provided rings values (list of values corresponding to each ring)
   * @param {TreeRender} renderedTree Generated phylogenetic tree from `phylotree` library
   */
  const renderRings = (metadata, rings, renderedTree) => {
    const tips = renderedTree.phylotree.getTips();
    const center = getCenter(tips[0]);
    const sortedPoints = tips.map(
      (tip) => {
        return {
          x: tip.screen_x,
          y: tip.screen_y,
          x_with_label: tip.screen_x + 1 * tip.data.name.length * Math.cos(tip.text_angle),
          y_with_label: tip.screen_y + 1 * tip.data.name.length * Math.sin(tip.text_angle),
          angle: tip.angle,
          name: tip.data.name,
          startAngle: 0,
          endAngle: 0,
          tip: tip
        };
      });
    // Place in order
    sortedPoints.sort((a, b) => a.angle - b.angle);
    sortedPoints.forEach((tip, index) => {
      const [start, stop] = getStartStop(index, sortedPoints);
      tip.startAngle = start;
      tip.endAngle = stop;
    });

    const longestLabel = sortedPoints.reduce((acc, val) => val.name.length > acc ? val.name.length : acc, 0);

    // const startRadius = 1.6 * sortedPoints[0].tip.radius + 8 * longestLabel; 
    const startRadius = 1.6 * max_radius + 1.8 * longestLabel;
    // eslint-disable-next-line no-magic-numbers
    const ringRadius = 20;

    for (let i = 0; i < rings.length; i += 1) {
      if (!displayRings[i]) {
        continue;
      }
      const innerRadius = startRadius + ringRadius * i;
      const ring = rings[i];
      renderRing(ring, metadata, i, sortedPoints, renderedTree, innerRadius, ringRadius, center);
    }
  }

  const generateTree = () => {
    const s = {};
    for (const node of selected_nodes) {
      s[node] = true;
    }
    // eslint-disable-next-line new-cap
    const tree = new phylotree(newick);
    // tree.scaleBranchLengths(b => b / 10)
    // TODO: Hover (internal) node => show tax label
    const nodeColors = generateColorMap(rank);
    const renderedTree = tree.render({
      // Make edges un-selectable
      "selectable": false,
      "max-radius": max_radius,
      // TODO: Better left-offset calculation based on number of rings around tree
      // eslint-disable-next-line no-magic-numbers
      "left-offset": (displayView === "radial" ? left_offset : 0) + (25. * displayRings.length),
      "align-tips": true,
      "zoom": false,
      // TODO: Include ability to perform re-roots, etc., using native menu
      "show-menu": false,
      container: "#" + id.toString(),
      'node-span': generateBubbleSize,
      "internal-names": showInternalNodes,
      'draw-size-bubbles': true,
      "font-size": "12px",
      "brush": false,
      "is-radial": displayView === "radial",
    }).style_edges((element, data) => {
      // TODO: Callout edge connecting to the terminal node
      element.style("stroke", nodeColors[data.target.data.name]);
    }).style_nodes((element, node) => {
      // Color text
      element.style("fill", nodeColors[node.data.name]);
      // Mouseover callouts
      callOutSelectedNode(element, node, s);
      // Color node based on traits data
      colorNodeByMetadataAndSelection(element, node, s);
      // Update selection on click
      element.on("click", () => {
        const nodes = toggleSelectedNodes(node, s);
        setNToggled(Math.abs(Object.keys(nodes).length - selected_nodes.length));
        setLastSelected(node.data.name);
        setShow(true);
        setProps({ selected_nodes: Object.keys(nodes) });
      });
    }).update(false);
    // Calling .update() with `false` prevents re-positioning bug

    if (displayView === "radial") {
      renderRings(metadata, rings, renderedTree);
      createLegend(renderedTree.svg,
        rings.map(r => r.legend_label),
        rings.map(r => r.legend_color),
        // These values calculated based on the position of the scale bar
        Math.abs(renderedTree.offsets[1] + renderedTree.options["left-offset"]),
        renderedTree.pad_height() + 10);
    }
    setTreeView(tree);
    setPhylotreeObj(renderedTree);
  };

  useEffect(() => {
    if (!newick || newick === "") {
      return;
    }
    generateTree();
  }, [selected_nodes, displayView, rank, displayRings]);  // TODO: Try to reduce re-renders using phylotree .update() method instead

  return (
    <>
      <Container>
        <Row className={"mb-1 mt-1"}>
          <Col className={'col-12 col-md-6 col-lg-3 d-flex justify-content-center'}>
            <RankSelector rank={rank} ranks={ranks} onSelect={(e, _) => setRank(e)} />
          </Col>
          <Col className={'col-12 col-md-6 col-lg-3 d-flex justify-content-center'}>
            <LayoutSelector onSelect={(e, _) => setDisplayView(e)} />
          </Col>
          <Col className={'col-12 col-md-6 col-lg-3 d-flex justify-content-center'}>
            <SelectAll onClick={() => {
              setProps({ selected_nodes: metadataKeys });
              setNToggled(metadataKeys.length);
              setLastSelected("all");
              setShow(true);
            }} />
          </Col>
          <Col className={'col-12 col-md-6 col-lg-3 d-flex justify-content-center'}>
            {display_visualize_button
              ? <VisualizeButton nSelected={selected_nodes.length}
                onClick={() => {
                  setNClicks((prev) => prev + 1);
                  setProps({ n_clicks: nClicks });
                }} />
              : <></>}
            <ClearSelection nSelected={selected_nodes.length}
              onClick={() => {
                setProps({ selected_nodes: [] });
              }} />
          </Col>
        </Row>
        <ZoomSlider />
        <TaxaSelector />
      </Container>
      <ToastDisplay nToggled={nToggled} lastSelectedID={lastSelected} show={show} setShow={setShow} />
      <Container id={id}
        ref={ref}
        style={{ overflow: "auto", height: height, width: width, border: "1px solid #cecece" }} />
    </>
  );
}

PhylotreeDash.defaultProps = {
  selected_nodes: [],
  metadata: {},
  rings: [],
  display_rings: [],
  newick: "",
  starting_rank: "genus",
  ranks: [],
  n_clicks: 0,
  colors: ['#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf'],
  trait_bubble_size: 3,
  left_offset: -50,
  max_radius: 500,
  display_visualize_button: true,
  height: "90vh",
  width: "100%",
};

PhylotreeDash.propTypes = {
  /**
   * The ID used to identify this component in Dash callbacks.
   */
  id: PropTypes.string.isRequired,

  /**
   * Tree formatted as a newick string
   */
  newick: PropTypes.string,

  /**
   * Object corresponding to assignments for each ID in the newick
   * tree.
   *
   * {id: {rank: "", ..., traits: {color: "", size: 0..1, rings: []}}}
   *
   * Traits are used within the tree to generate node bubble `size` (0 or 1) and `color` (hex string).
   *
   * `rank` is expected to be a member of the ``ranks`` array argument
   *
   * Currently supported trait fields:
   *
   *   "size":   1 (integer) corresponds to this Component's `trait_bubble_size`
   *
   *   "color":  color string for node
   *
   *   "rings":  node value within each ring defined by Component's ``rings``. In `categorical` rings, these
   *   values represent indices in the list of `values` for that ring. In `linear` rings, these values will
   *   be used to interpolate a color based on that ring's `values` colors list.
   */
  metadata: PropTypes.object,

  /**
   * Define data to render for rings around tree. Should be same length as `display_rings`.
   *
   * The array should contain objects that define information about each ring:
   *
   * {kind: "categorical", colors: ["color", "color", "color"], legend_label: "", legend_color: ""}
   *
   * {kind: "linear", colors: ["color", "color", "color"], legend_label: "", legend_color: ""}
   *
   * When `kind` == "categorical", each color in `colors` is a category color.
   *
   * When `kind` == "linear", colors in `colors` will be used to create a color scheme.
   */
  rings: PropTypes.array,

  /**
   * Boolean array describing if ring should be displayed. Should be same length as `rings`.
   */
  display_rings: PropTypes.array,

  /**
   * Taxonomic rank at which to display bubbles
   */
  starting_rank: PropTypes.string,

  /**
   * List of tax ranks
   */
  ranks: PropTypes.array,

  /**
   * List of currently selected nodes in phylo tree
   */
  selected_nodes: PropTypes.array,

  /**
   * Number of times `Visualize` has been clicked
   */
  n_clicks: PropTypes.number,

  /**
   * List of colors with which to color ranks
   */
  colors: PropTypes.array,

  /**
   * Size to render bubbles that have trait annotations
   */
  trait_bubble_size: PropTypes.number,

  /**
   * Left margin value (negative values move plot closer to left edge)
   */
  left_offset: PropTypes.number,

  /**
   * Maximum radius of tree
   */
  max_radius: PropTypes.number,

  /**
   * Display the 'Visualize' button
   */
  display_visualize_button: PropTypes.bool,

  /**
   * Height of tree component
   */
  height: PropTypes.string,

  /**
   * Width of tree component
   */
  width: PropTypes.string,

  /**
   * Dash-assigned callback that should be called to report property changes
   * to Dash, to make them available for callbacks.
   */
  setProps: PropTypes.func
};
